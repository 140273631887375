@charset "UTF-8";
/*------------------------------------------------------------------
[Master Stylesheet]
Project:	Pogody
++++ Imports ++++
++++ Reset ++++
++++ Main ++++
++++ Mediaqueries ++++
-------------------------------------------------------------------*/
/****************************/
/** Imports **/
/****************************/
@import url(./assets/css/jquery.countdown.css); 
@import url(./assets/css/animate.css);
@import url(./assets/css/owl.carousel.css);
@import url(./assets/css/component2.css);
@import url(./assets/css/inputtext.css);

/*=======================================================================
[] Fonts
=========================================================================*/
@font-face {
  font-family: 'ProximaNova_Regular';
  src:  url("./assets/fonts/ProximaNova-Regular.woff") format('woff'),
       url("./assets/fonts/Proxima Nova Regular.otf") format('otf'),
       url("./assets/fonts/Proxima Nova Regular.woff2") format('woff2'),
       url("./assets/fonts/Proxima Nova Regular.ttf") format('ttf');
  font-display: swap;
}

@font-face {
  font-family: 'ProximaNova-Light';
  src: url("./assets/fonts/ProximaNova-Light.woff") format('woff'),
       url("./assets/fonts/Proxima Nova Light.otf") format('otf'),
       url("./assets/fonts/Proxima Nova Light.woff2") format('woff2'),
       url("./assets/fonts/Proxima Nova Light.ttf") format('ttf');
  font-display: swap;
}
@font-face {
  font-family: 'ProximaNova_SemiBold';
  src: url("./assets/fonts/ProximaNova-Semibold.woff") format('woff'),
       url("./assets/fonts/Proxima Nova Semibold.otf") format('otf'),    
       url("./assets/fonts/Proxima Nova Semibold.ttf") format('ttf'),
       url("./assets/fonts/Proxima Nova Semibold.woff2") format('woff2');
  font-display: swap;
}


:root{
  --gray: #4a4a4a;
  --blue: #20208c;
  --green: rgba(16, 172, 73, 0.919);
}

/****************************/
/** Reset **/
/****************************/
html, body, div, span, applet, object, iframe, h1, h3, .side-right #countdown span,
.side-right #countdown li.seperator, h2, h3, h4, h5, h6, p, .address-detail a, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

strong {
  font-weight: bold; }

body {
  color: #fff;
  background: #f6f7f9;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: 1.5;
  overflow: hidden; }

a:hover, a:active {
  outline: 0; }

button:focus {
  border: none;
  outline: 0; }

ul {
  list-style: none; }

a {
  color: #fff;
  text-decoration: none; }

a:hover {
  color: #fff;
  text-decoration: none; }

a:focus {
  color: #fff;
  text-decoration: none;
  outline: none; }

h1, h3, .side-right #countdown span,
.side-right #countdown li.seperator {
  color: #000;
  font-size: 80px;
  letter-spacing: -1px;
  font-weight: 600; }

h3 {
  font-size: 55px;
  font-weight: 600;
  text-transform: capitalize;
  letter-spacing: -1px; }

p, .address-detail a {
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  line-height: 25px; }

.text-about-desc {
  font-size: 1.2vw;
  text-align: justify;
  margin-bottom: 3% !important;
  font-family: "ProximaNova-Light";
}

.force-UTF-8 {
  content: "¡"; }


  .mat-h5, .mat-typography h5 {
    margin: 0.3vw 0 0.3vw !important;
}
/****************************/
/** Main **/
/****************************/
.pad-zero {
  padding: 0; }

.pad-left-zero {
  padding-left: 0; }

.pad-right-zero {
  padding-left: 0; }

  .ball {
    position: absolute;
    border-radius: 100%;
    opacity: 0.7;
  }

.opacity-hide {
  opacity: 0;
  visibility: hidden; }

.preloader-it {
  background: #fff;
  position: fixed;
  z-index: 100;
  height: 100%;
  width: 100%;
  overflow: hidden !important; }

/* Effect 1: YouTube loading */
.la-anim-1 {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 10px;
  background: #000;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
  pointer-events: none; }

.la-anim-1::after {
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  height: 100%;
  background: #000;
  content: '';
  opacity: 0;
  -webkit-transition: opacity 0.2s, box-shadow 2s 4s;
  transition: opacity 0.2s, box-shadow 2s 4s;
  -webkit-transform: rotate(2deg) translateY(-2px);
  transform: rotate(2deg) translateY(-2px); }

.la-anim-1.la-animate {
  z-index: 100;
  opacity: 1;
  -webkit-transition: -webkit-transform 5s ease-in, opacity 1s 5s;
  transition: transform 5s ease-in, opacity 1s 5s;
  -webkit-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0); }

.la-anim-1.la-animate::after {
  opacity: 1; }

.main-menu .call-to-action {
  position: relative; }

.side-left {
  background: #fff;
  padding-top: 11%; 
  overflow: hidden;
}
  .side-left .small-hr {
    margin-top: 17px;
    margin-bottom: 25px; }
  .side-left p, .side-left .address-detail a, .address-detail .side-left a {
    padding-bottom: 25px; }
  .side-left .content-wrap {
    height: 100%; }

.preloader {
  background: #fff; }

p.tagline, .address-detail a.tagline {
  font-size: 1.5vw;
  font-weight: 600;
  line-height: 45px;
  letter-spacing: -1px; }
  p.tagline {
    text-align: center;
    font-family: 'ProximaNova_SemiBold';
  }
  .list-aboutus li{
    color: #ffffff;
    font-size: 1.2vw;
    font-family: "ProximaNova_SemiBold";
}
.link-pku {
  font-family: "ProximaNova_SemiBold";
}
#typed {
  font-family: 'ProximaNova-Light';
  font-size: 1.5vw;
  color:#4a4a4a;
  line-height: 1.5;
}
.page.page-left .detail-info h3 {
  font-size: 1.5vw;
  /* color: #20208c; */
  color: #e7e7e7;
    font-family: "ProximaNova_SemiBold";
}
.mat-select-arrow{
  color:#000 !important;
}
.mat-select-value-text{
  font-family: 'ProximaNova-Light';
  color: #000 !important;
  font-size: 1vw;
}
.side-right {
  /* background: transparent url("./assets/img/background-home.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #000;
  right: 0;  */
}
.side-language {
  position: absolute;
  width: 35%;
  right: 17%;
  top: 1.8vw;
  display: flex;
  flex-direction: row;
}
.side-language .label-language {
flex-grow: 4;
font-size: 1vw;
font-family: 'ProximaNova-Light';
color: #20208c;
font-weight: 500;
}
app-select-language {
  flex-grow: 3;
  font-size: 1vw;
font-family: 'ProximaNova-Light';
}
#select-box1 {
  font-size: 1vw;
  font-family: 'ProximaNova-Light';  
  width: 6.5vw;
  text-align: center;
  border: none;
  color: #20208c;
  background: transparent;
}
select option {
  background: #20208c !important;
  color: #fff !important;
  box-shadow: none;
}



  .side-right #countdown {
    padding-top: 28px; }
  .side-right .counter-content {
    padding-top: 24%; }
    .side-right .supported-content {
      padding-top: 5%;
      display: block;
      position: absolute;
      width: 100%;
      bottom: 15%;
    }
  .side-right #countdown span,
  .side-right #countdown li.seperator {
    color: #000;
    line-height: 97px; }
  .side-right .trans-overlay-dark {
    background: #fff;
    /* The Fallback */
    background: rgba(255, 255, 255, 0.1);
    position: absolute;
    left: 0;
    z-index: -2; }

.align-center, .address-detail {
  /* overflow: hidden; */
  clear: both;
  float: none;
  margin: 0 auto; }

.type-wrap {
  min-height: 120px;
  padding-bottom: 40px; }

.small-hr {
  background: #20208c none repeat scroll 0 0;
  display: block;
  height: 0.3vw;
  width: 20%; 
  margin: 0 auto;}
fa-info {
  color: #20208c;
}
.bg-img, .page-left .page-inner, .page-right .page-inner {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }

.page-left .page-inner {
  background: transparent url("assets/img/background.png"); }
  .page-left .page-inner .small-hr {
    margin-top: 9px;
    margin-bottom: 23px; }
  .page-left .page-inner .trans-overlay-dark-about {
    background: #fff;
    /* The Fallback */
    background: rgba(255, 255, 255, 0.4);
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 1; }

.trans-overlay-dark {
  background: #fff;
  /* The Fallback */
  background: rgba(255, 255, 255, 0.4);
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1; }

.page-right .page-inner {
  /* padding-bottom: 100px; */
  height: 100vh;
  background: transparent url("./assets/img/email-background.jpg") no-repeat center; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
  .page-right .page-inner .trans-overlay-dark-contact {
    /* background: #fff; */
    /* The Fallback */
    /* background: rgba(255, 255, 255, 0.8); */
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 1; }

 .page-bottom {
   display: none;
   position: absolute;
   bottom: 100%;
   height: 100%;
   width: 100%;
   z-index: 1;
   background: rgb(250, 247, 247);
   overflow: hidden;
 }

  .page-right .page-inner .copyright {
    display: none; }

  .page-right .title-contact {
    color: #FFFFFF;
    font-family: 'ProximaNova-Light';
  }
  .mat-radio-outer-circle {
    border-color: #ccc !important;
}
  .page-right .form-control {
    color: #FFFFFF;
    font-family: 'ProximaNova-Light';
  }
  .page-right .form-control::placeholder {
    color: #FFFFFF;
    font-family: 'ProximaNova-Light';
  }
.detail-info {
  position: relative;
  z-index: 2;
  padding-top: 5%;
  text-align: left; }

#qualities .slick-slide  {
  max-width: 20%;
  margin: 0.5vw;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-appearance: none;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; 
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 0.5vw;
}
#qualities .owl-carousel.owl-theme .owl-controls span {
  background: none;
  border: 2px solid #000;
  border-radius: 0;
  display: inline-block !important;
  height: 12px;
  margin: 5px 7px;
  opacity: 1;
  width: 12px; }
.owl-carousel.owl-theme .owl-dot {
  float: left; }
.owl-carousel.owl-theme .owl-nav {
  display: none; }
.owl-carousel.owl-theme .owl-dot.active span {
  background: #000; }

#qualities .slick-slide  {
  background: #fff; }
#qualities .slick-slide:hover {
  box-shadow: 0 0 0 4px #000 inset;
  -webkit-box-shadow: 0 0 0 4px #000 inset;
  -ms-box-shadow: 0 0 0 4px #000 inset;
  -moz-box-shadow: 0 0 0 4px #000 inset; }

/* #support .slick-slide  {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  padding-top: 2.2vw;
  padding-bottom: 1vw;
} */
.notify-btn {
  padding-top: 50px;
  padding-bottom: 10px; }

.table {
  display: table; }

.align-middle {
  display: table-cell;
  vertical-align: middle; }

.nav-btn, .contact, .about {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  position: absolute;
  top: 0;
  z-index: 11;
  cursor: pointer;
  -moz-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
  -webkit-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out; }

.contact, .about {
  color: #000; }
  #qualities {
    color: #4a4a4a;
  }
 #team, #support {
  color: #4a4a4a;
  padding-top: 52px; }
  #qualities span, #team span, #support span {
    display: block; 
    font-size: 1vw;
    font-family: 'ProximaNova-Light';
    margin: 0 auto;
    text-align: center;
    line-height: 1.5;
  }
  #qualities .item, #team .item, #support .item {
    padding: 0 1.5vw;
    overflow: hidden; }
    #qualities .item {
      padding: 0 !important;
    }
  #qualities .fa-qual, #team .fa-qual{
    font-size: 36px;
    padding-top: 28px;
    padding-bottom: 5px; }
    #qualities .fa-qual {
      display: block;
      position: relative;
      width: 100%;
      padding-bottom: 2%;
    }
    #qualities .fa-qual .mark-thanks{
      width: 25%;
      position: absolute;
      top: 0;
      left: 0;
    }
    #qualities .fa-qual .profile{
      width: 50%;
      height: auto;
      position: relative;
      margin: 0 auto;
    }

.profile{
  background: #FFFFFF !important;
  border-radius: unset !important;
}
    #qualities {
      margin-bottom: 5%;
    }
  #support .fa-qual {
    font-size: 1vw;
  }
  #qualities .qual-tag, #qualities .name, #team .qual-tag, #team .name, #support .qual-tal {
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase; }
    #support .name {
      font-size: 2vw;
    font-weight: 600;
    text-transform: uppercase;
    }
    #support .equal-desc {
      font-size: 1.2vw;
      font-family: 'ProximaNova-Light';      
    }
    #qualities .qual-tag {
      font-size: 1vw !important;
      position: relative;
      margin-top: 6%;
      /* position: absolute;
      right: 2%;
      top: 8%;
      width: 70%; */
      left: auto;
      text-align: center;
      /* padding-left: 2vw; */
      font-family: "ProximaNova_Regular";
    }

    #qualities .slick-track
    {
        display: flex !important;
    }
    
    #qualities .slick-slide
    {
        height: inherit !important;
    }

  #qualities .qual-desc, #team .qual-desc {
    font-size: 1vw;
    font-weight: 300;
    padding: 25px 0; }

    #qualities .qual-desc {
      margin-top: 3%;
      font-family: "ProximaNova-Light";
      text-align: center;
      padding: 1vw;
    }
    /*TABLE */
    #no-more-tables {
      margin-top: 8% !important;
  }
  #no-more-tables-pat {
    margin-top: 8% !important;
}

  table th {
      font-size: 0.8vw !important;
      font-family: 'ProximaNova-Light' !important;
      color: #FFFFFF !important;
    background: #6b84ec;
  }
  table tbody {
      font-size: 0.8vw !important;
      font-family: 'ProximaNova-Light' !important;
      color: rgb(49, 47, 47) !important;
  }
  .color-back-table {
    background: #ddd !important;
  }


/*=======================================================================
[30] Table Area
=========================================================================*/
.text-left {
  text-align: left !important;
}
.table{
	width: 100%;
	margin-top: 2%;
	vertical-align: middle;
}
.table  th{
  border:1px solid #ddd;
  text-align: center;
  font-family: 'ProximaNova_Regular';
	background-color: #1E66AD;
	color:#ffffff;
	min-width: 90px;
}
.table>thead>tr .captions-table {
  border:0 !important;
}
.table>thead>tr .caption1{
  width:15%;
}
.table>thead>tr .caption2{
 width:8%;
}
.table>thead>tr .caption3{
  width:8%;
}
.table>thead>tr .caption4{
  width:8%;
  color:#312F2F !important;
}
.table>thead>tr .caption5{
  width:12%;
  color:#312F2F !important;
}
.table>thead>tr .caption6{
  width:8%;
  color:#312F2F !important;
}
.table>thead>tr .caption7{
  width:13%;
}
.table td{
  padding-top: 2% ;
  border:1px solid #ddd;
  text-align: center;
}

.table tbody tr:nth-child(even){
	background-color: #f5f5f5;
}
.title-table {
  background-color: transparent !important;
  color: var(--gray) !important;
  font-weight: normal !important;
  text-align: center;
}
.title-table p{
  padding:0 !important;
  margin:0 !important;
}
.title-table p a {
 text-decoration: none;
 color: var(--sixth);
}
.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
    padding-top: 12px;
    padding-bottom: 12px;
    line-height: 1.2;
    vertical-align: middle;
    border-top: 1px solid #ddd;
}
.table>tbody>tr>td p, .table>tbody>tr>th p, .table>thead>tr>td p, .table>thead>tr>th p{
    line-height: 1.2;
    color: #666666;
}
.table>thead>tr>td, .table>thead>tr>th {
    line-height: 1.2;
    text-align: center;
    vertical-align: middle;
    border-top: 1px solid #ddd;
}
.second {
  font-weight: 900;
  color:#666666;
}

/*Table responsive*/

@media(max-width: 811px){
	.table thead{
		display: none;
	}

	.table, .table tbody, .table tr, .table td{
		display: block;
		width: 100%;
		font-size:12px;
	}
	.table tr{
		margin-bottom:15px;
	}

	.table td{
	  font-family: 'OpenSans-Regular';
		text-align: left;
		display:block;
		position: relative;
	}
	.table td::before{
		content: attr(data-label) ": ";
		position: relative;
		left:0;
		width: 40%;
		margin-right: 10px;
		font-size:13px;
		font-weight: bold;
		text-align: left;
    color: #1E66AD;
	}
}
  /************************************************/


    #support .equal-desc {
      font-size: 1vw;
      font-weight: 500;
      padding-top: 0.5vw;
    }

    #support .logo-sup {
      display: block;
      margin: 0 auto;
      position: relative;
      width: 50%;
      margin-bottom: 7%;
    }

.team-sec {
  margin-top: 20px !important;
  padding-bottom: 100px; }

#team {
  padding-top: 19px; }
  #team .item {
    padding: 0; }
  #team .name {
    font-size: 16px;
    padding-top: 18px; }
  #team .desg {
    padding: 0;
    font-size: 13px;
    text-transform: uppercase; }
  #team .team-overlay {
    background: rgba(0, 0, 0, 0.3) none repeat scroll 0 0;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease 0.3s;
    -webkit-transition: all 0.3s ease 0.3s; }
  #team .team-img-wrap {
    position: relative; }
    #team .team-img-wrap:hover .team-overlay {
      opacity: 1;
      visibility: visible; }
    #team .team-img-wrap .social-icons {
      padding-top: 30%;
      text-align: center; }
      #team .team-img-wrap .social-icons li {
        display: inline; }
    #team .team-img-wrap .social-icon {
      padding: 0 10px; }
      #team .team-img-wrap .social-icon i {
        color: #fff;
        -moz-transition: all 0.4s ease;
        -o-transition: all 0.4s ease;
        -webkit-transition: all 0.4s ease;
        transition: all 0.4s ease; }
      #team .team-img-wrap .social-icon:hover i.fa-facebook {
        color: #3B5998; }
      #team .team-img-wrap .social-icon:hover i.fa-twitter {
        color: #00aced; }
      #team .team-img-wrap .social-icon:hover i.fa-linkedin {
        color: #007bb6; }
      #team .team-img-wrap .social-icon:hover i.fa-behance {
        color: #1769ff; }

.back-left, .back-left:focus, .back-left:hover {
  left: 45px;
  color: #000; }

.back-right, .back-right:focus, .back-right:hover {
  color: #fff;
  right: 45px; }

.btn-default.active.focus, .btn-default.active:focus, .btn-default.active:hover, .btn-default.focus:active, .btn-default:active:focus, .btn-default:active:hover, .open > .dropdown-toggle.btn-default.focus, .open > .dropdown-toggle.btn-default:focus, .open > .dropdown-toggle.btn-default:hover {
  background: #000;
  border-color: #000;
  color: #fff;
  outline: none; }

.btn {
  font-size: 16px;
  color: #fff;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-color: #000;
  border-radius: 0;
  height: 57px;
  width: 203px;
  background: #000; }
  .btn:hover, .btn:focus, .btn:active {
    background: #000;
    color: #fff;
    border-color: #000;
    outline: none; }
    .btn:hover .arrow:after, .btn:focus .arrow:after, .btn:active .arrow:after {
      width: 20px; }
  .btn .button-label {
    padding: 10px 10px 10px 8px; }
  .btn .arrow-wrap {
    padding: 15px;
    padding-right: 10px; }
  .btn .arrow {
    padding-left: 0;
    margin-left: 29px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 0 6px 12px;
    border-color: transparent transparent transparent #20208c; }
    .btn .arrow:after {
      backface-visibility: hidden;
      background: #20208c none repeat scroll 0 0;
      content: "";
      height: 3px;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translate(0px, -50%);
      -webkit-transform: translate(0px, -50%);
      transition: width 0.3s ease 0.3s;
      -webkit-transition: width 0.3s ease 0.3s;
      width: 15px; }
  .btn span {
    display: inline-block;
    float: left;
    position: relative;
    padding-left: 4px; }
    .btn span svg {
      fill: #20208c; }

.black {
  color: #000; }

.close-notify {
  display: block;
  font-weight: 300;
  margin-bottom: 30px;
  margin-top: 22px;
  width: 41px; }
  .close-notify:hover, .close-notify:focus, .close-notify:active {
    color: #FFFFFF; }

.notify-wrap i {
  display: none; }
.notify-wrap #message {
  padding-left: 0;
  padding-top: 15px;
  color: #FFFFFF; }
.notify-wrap .btn {
  padding: 0;
  margin-top: 25px;
  width: 57px; }
  .notify-wrap .btn .arrow {
    margin-left: 8px; }
.notify-wrap .notify-input-box {
  border: none;
  min-height: 50px;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 600;
  padding-left: 0;
  border-radius: 0;
  box-shadow: none; }
  .notify-wrap .notify-input-box:hover, .notify-wrap .notify-input-box:focus, .notify-wrap .notify-input-box:active {
    border: none;
    box-shadow: none; }
.notify-wrap #notifyMe {
  width: 441px; }
.notify-wrap .input__label--hoshi::before,
.notify-wrap .input__label--hoshi::after {
  border-bottom: 4px solid #FFFFFF; }
.notify-wrap .input__label--hoshi::after {
  border-bottom: 8px solid #FFFFFF; }

.form-control::-webkit-input-placeholder {
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase; }
.form-control:-moz-placeholder {
  /* Firefox 18- */
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase; }
.form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase; }
.form-control:-ms-input-placeholder {
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase; }

.section-title {
  padding-top: 11%;
  padding-bottom: 18px; }

.remain-days {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  display: block;
  padding-top: 10px; }

.clients_block {
  min-height: 59px; }

.form-horizontal .form-group {
  margin-left: 0;
  margin-right: 0; }

.input-wrap {
  position: relative;
  overflow: hidden; }

.page-right input {
  height: 50px; }
.page-right .bg-danger, .page-right .bg-success {
  background-color: transparent; }
.page-right .form-control {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background: transparent none repeat scroll 0 0;
  border: none;
  box-shadow: none;
  color: #FFFFFF;
  display: block;
  overflow: hidden;
  font-size: 18px;
  font-weight: 600;
  width: 441px;
  padding-left: 0;
  padding-bottom: 18px;
  padding-top: 13px; }
  .page-right .form-control:focus {
    border-color: rgb(255, 255, 255); }
.page-right h3 {
  padding-bottom: 52px; }
.page-right .form-group {
  margin-bottom: 24px; }
.page-right .icon {
  padding-left: 0; }
  .page-right .icon i {
    font-size: 15px; }
.page-right form {
  padding-bottom: 35px;
  width: 441px; }
.page-right .btn {
  padding: 0; }
.page-right .btn-default {
  margin-top: 15px;
  position: relative;
  background: #ffffff;
  width: 57px; 
 }
  .page-right .btn-default .arrow {
    margin-left: 8px; }
.page-right .map {
  padding-top: 75px; }
  .page-right .map a {
    color: #000;
    font-weight: 600;
    font-size: 18px;
    text-transform: uppercase; }
  .page-right .map span {
    display: inline-block; }
  .page-right .map .go-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 0 6px 12px;
    border-color: transparent transparent transparent #000;
    display: inline-block;
    margin-left: 12px; }

.address-detail {
  text-align: left; }
  .address-detail p, .address-detail a {
    font-size: 13px;
    padding-bottom: 27px;
    padding-top: 9px;
    font-weight: 500;
    line-height: 25px; }
  .address-detail .icon-detail {
    font-size: 14px;
    display: block;
    padding-bottom: 10px;
    color: #000;
    text-transform: uppercase; }
  .address-detail a {
    font-size: 14px;
    display: block;
    padding-bottom: 0;
    padding-top: 0; }
  .address-detail i {
    display: block; }

.fa {
  color: #000;
  -webkit-appearance: none;
  -webkit-backface-visibility: hidden; }

.copyright {
  bottom: 0%;
  left: 1.5%;
  position: fixed;
 }
  .copyright i {
    line-height: 25px; }
  .copyright p, .copyright .address-detail a, .address-detail .copyright a {
    font-size: 12px;
    display: inline-block;
    float: left; }
  .copyright .social-icon {
    color: #000;
    cursor: pointer;
    display: block;
    float: left;
    text-align: center !important;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease; }
    .copyright .social-icon i {
      -moz-transition: all 0.4s ease;
      -o-transition: all 0.4s ease;
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease; }
    .copyright .social-icon:hover i.fa-facebook {
      color: #3B5998; }
    .copyright .social-icon:hover i.fa-twitter {
      color: #00aced; }
    .copyright .social-icon:hover i.fa-linkedin {
      color: #007bb6; }
    .copyright .social-icon:hover i.fa-behance {
      color: #1769ff; }
  .copyright .social-icons > li {
    display: inline-block;
    float: left;
    padding-right: 15px; }
  .copyright a.social-icon {
    height: 20px;
    line-height: 12px !important;
    overflow: hidden;
    text-shadow: none;
    width: 20px;
    display: inline-block;
    float: left;
    color: #000; }
  .copyright .social-icons {
    float: left; }

@-webkit-keyframes border-widthc {
  0% {
    width: 0%; }
  100% {
    width: 100%; } }
@keyframes border-widthc {
  0% {
    width: 0%; }
  100% {
    width: 100%; } }
@-webkit-keyframes border-heightc {
  0% {
    height: 0%; }
  100% {
    height: 100%; } }
@keyframes border-heightc {
  0% {
    height: 0%; }
  100% {
    height: 100%; } }
.call-to-action {
  display: block;
  height: 41px;
  width: 41px; }
  .call-to-action:hover .left, .call-to-action:hover .right {
    -webkit-animation-name: border-heightc;
    animation-name: border-heightc;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    animation-delay: 0;
    -webkit-animation-delay: 0;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  .call-to-action:hover .top, .call-to-action:hover .bottom {
    -webkit-animation-name: border-widthc;
    animation-name: border-widthc;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    animation-delay: 0;
    -webkit-animation-delay: 0;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out; }
  .call-to-action i {
    left: 0;
    right: 0;
    position: absolute;
    top: 50%;
    text-align: center;
    margin: 0 auto;
    font-size: 15px;
    transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%); 
    color: #20208c;}
  .call-to-action span {
    background: #20208c;
    position: absolute;
    display: block; }
  .call-to-action .top {
    height: 3px;
    left: 0;
    top: 0;
    width: 100%; }
  .call-to-action .bottom {
    height: 3px;
    bottom: 0;
    right: 0;
    width: 100%; }
  .call-to-action .left {
    height: 100%;
    left: 0;
    width: 3px;
    bottom: 0; }
  .call-to-action .right {
    width: 3px;
    right: 0;
    height: 100%;
    top: 0; }

.about {
  left: 29px;
  top: 1.8vw; }

.open-left .about {
  left: 50px; }

.contact {
  right: 29px;
  top: 1.8vw; }

.open-right .contact {
  right: 50px; }

#astronomy {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: -1; }
  #astronomy #demo-canvas {
    height: 100%;
    left: 0;
    position: absolute;
    width: 100%; }

@keyframes moveclouds {
  0% {
    margin-left: 100%; }
  100% {
    margin-left: -100%; } }
@-webkit-keyframes moveclouds {
  0% {
    margin-left: 100%; }
  100% {
    margin-left: -100%; } }
@keyframes moveclouds2 {
  0% {
    margin-left: 100%; }
  50% {
    margin-left: 100%; }
  100% {
    margin-left: -100%; } }
@-webkit-keyframes moveclouds2 {
  0% {
    margin-left: 100%; }
  50% {
    margin-left: 100%; }
  100% {
    margin-left: -100%; } }
.layer {
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%; }
  .layer img {
    max-width: 100%;
    height: 100%; }

.clouds {
  overflow: hidden; }
  .clouds .cloud {
    bottom: 0;
    left: 0;
    position: absolute;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    width: 100%;
    height: 100%;
    will-change: transform; }
  .clouds .cloud-1 {
    -webkit-animation: moveclouds 25s linear infinite;
    animation: moveclouds 25s linear infinite; }
  .clouds .cloud-2 {
    -webkit-animation: moveclouds 50s linear infinite;
    animation: moveclouds 50s linear infinite; }
  .clouds .cloud-3 {
    -webkit-animation: moveclouds2 35s linear infinite;
    animation: moveclouds2 35s linear infinite; }

    .mat-card {
      background: #FFFFFF !important;
  }
  
  .mat-cell {
    color: #4a4a4a;
}
.mat-table th {
  background: #666666 !important;
  font-family: 'ProximaNova_SemiBold' !important;
  text-align: center !important;
  font-size: 1vw !important;
  vertical-align: middle;
  padding: 1vw !important;
}
.mat-table td {
  color: #4a4a4a !important;
  font-family: 'ProximaNova_Regular' !important;
  text-align: center !important;
  font-size: 0.8vw !important;
  vertical-align: middle;
  border-color: #ccc;
  padding: 0.5vw !important;
}
 .mat-table tbody, .mat-table tfoot, mat-header-row, mat-row, mat-footer-row,  .mat-table-sticky {
  background: #FFFFFF;
}
.mat-paginator {
  background: #ccc;
  color: #4a4a4a;
  font-family: 'ProximaNova-Regular';
  font-size: 1vw;
}
.mat-table {
    font-family: 'ProximaNova_Regular';
}
.mat-form-field-label {
  color: #4a4a4a;
  border-bottom: 1px solid #4a4a4a;
}
.mat-card-header-text {
  width: 100% !important;
}
mat-card {
  -moz-box-shadow: 0 1px 2px 0 rgba(60,64,67,.3),0 1px 3px 1px rgba(60,64,67,.15) !important;
  box-shadow: 0 1px 2px 0 rgba(60,64,67,.3),0 1px 3px 1px rgba(60,64,67,.15) !important;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1) !important;
}

/*****************************************************/
/* Mediaqueries */
/*****************************************************/
@media only screen and (max-width: 1710px) {
  h1, h3, .side-right #countdown span,
  .side-right #countdown li.seperator {
    font-size: 65px; }

  .open-left .about {
    left: 30px; }

  .open-right .contact {
    right: 30px; } }
@media only screen and (max-width: 1530px) {
  h1, h3, .side-right #countdown span,
  .side-right #countdown li.seperator {
    font-size: 50px; }

  h3 {
    font-size: 35px; }

  .detail-info {
    padding-top: 8%; }

  .side-right #countdown span, .side-right #countdown li.seperator {
    line-height: 59px; }

  .btn {
    font-size: 12px;
    height: 40px;
    width: 143px; }
    .btn .button-label {
      padding: 5px 12px 5px 5px; }
    .btn .arrow-wrap {
      padding: 9px 6px 6px; }
    .btn .arrow {
      border-width: 5px 0 5px 10px;
      margin-left: 8px; }
      .btn .arrow:after {
        height: 2px;
        right: 7px; }

  .notify-wrap .btn, .page-right .btn {
    width: 43px; }
    .notify-wrap .btn .arrow-wrap, .page-right .btn .arrow-wrap {
      margin-left: 4px; } }
@media only screen and (max-width: 1366px) {
  .btn .arrow-wrap {
    padding: 8px 6px 6px; }
  .btn .button-label {
    padding: 5px 10px 5px 5px; }

  p.tagline, .address-detail a.tagline, .address-detail a.tagline {
    font-size: 25px; }

  .notify-btn {
    padding-top: 0; }

  .notify-wrap .notify-input-box,
  .page-right .form-control {
    font-size: 14px; }

  .form-control::-webkit-input-placeholder {
    font-size: 14px; }
  .form-control:-moz-placeholder {
    /* Firefox 18- */
    font-size: 14px; }
  .form-control::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 14px; }
  .form-control:-ms-input-placeholder {
    font-size: 14px; }

  .page-right .map {
    overflow: hidden;
    clear: both;
    float: none;
    padding-top: 45px; }
    .page-right .map a {
      font-size: 14px; }
    .page-right .map .go-arrow {
      border-width: 5px 0 5px 10px; } }
@media only screen and (max-width: 1082px) {
  h1, h3, .side-right #countdown span,
  .side-right #countdown li.seperator {
    font-size: 40px; }

  h3 {
    font-size: 30px; }

  p.tagline, .address-detail a.tagline,
  .address-detail a.tagline {
    font-size: 20px;
    line-height: 35px; }

  .side-right #countdown {
    padding-top: 8px; 
    text-align: center;}
  .side-right .counter-content {
    padding-top: 24%; }
    .side-right .supported-content {
      padding-top: 5%; }
  .page-right .map {
    overflow: hidden;
    clear: both;
    float: none;
    padding-top: 45px; }
  .page-right form {
    width: 350px; }

  .close-notify {
    width: 30px; }

  .call-to-action {
    height: 30px;
    width: 30px; }
    .call-to-action i {
      font-size: 13px; }

  .logo img {
    width: 50px; }
  .title-logo {
    font-size: 5.5vw !important;
  }
  }
@media only screen and (max-width: 973px) {
  .copyright .social-icons {
    padding-bottom: 12px; } }
@media only screen and (max-width: 971px) {
  .open-right .contact {
    right: 20px; }

  .open-left .about {
    left: 20px; } }
@media only screen and (max-width: 870px) {
  h1, h3, .side-right #countdown span,
  .side-right #countdown li.seperator {
    font-size: 30px; }
.side-right {
  margin-top: 30%;
}
  h3 {
    font-size: 30px; }

  p.tagline, .address-detail a.tagline, .address-detail a.tagline {
    font-size: 18px;
    line-height: 30px; }
  p.tagline {
    font-size: 3vw;
  }
  .text-about-desc {
    font-size: 2.5vw;
  }
  .copyright p, .copyright .address-detail a, .address-detail .copyright a, .copyright .address-detail a {
    font-size: 12px; } 
    #qualities .qual-tag {
      font-size: 2.2vw !important;
    }
    #qualities .qual-desc {
      font-size: 2.2vw !important;
    }
    #qualities .fa-qual {
      font-size: 4vw !important;
    }
    #support .qual-desc {
      font-size: 2.2vw !important;
    }
    #support .logo-sup {
      width: 42%;
      bottom: 33%;
      left: 29%;
    }
    .counter-content {
      opacity: 0;
    }
  }
@media only screen and (max-width: 834px) {
  .notify-wrap .notify-input-box {
    font-size: 12px; }
  .notify-wrap .form-control::-webkit-input-placeholder {
    font-size: 12px; }
  .notify-wrap .form-control:-moz-placeholder {
    /* Firefox 18- */
    font-size: 12px; }
  .notify-wrap .form-control::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 12px; }
  .notify-wrap .form-control:-ms-input-placeholder {
    font-size: 12px; } }
@media only screen and (max-width: 766px) {
  h1, h3, .side-right #countdown span,
  .side-right #countdown li.seperator {
    font-size: 50px; }

  h3 {
    font-size: 30px; }

  .side {
    position: relative;
    width: 100%; }
    .side.side-right .counter-content {
      padding-bottom: 1vw;
      padding-top: 1vw; }
      .side.side-right .supported-content {
        padding-bottom: 1vw;
        padding-top: 1vw; }
    .side.side-left {
      padding-top: 80px;
      padding-bottom: 47px; }
      .side.side-left .copyright {
        display: none; }

  #message {
    font-size: 12px;
    padding-top: 15px;
    padding-bottom: 20px; }

  .logo img {
    width: auto; 
  }
.side.logo {
  text-align: left !important;
}
  .page {
    position: static;
    width: 100%; }
    .page .page-inner {
      padding: 80px 0; }
    .page .detail-info {
      padding-top: 0; }
      .page .detail-info .panel-body {
        padding-bottom: 0; }
    .page.page-left {
      -webkit-transform: translateX(0px);
      -ms-transform: translateX(0px);
      transform: translateX(0px);
      position: static; }
      .page.page-left .detail-info h3 {
        padding-bottom: 2vw;
      font-size: 4vw; }
      .page.page-left .col-sm-3, .page.page-left .col-xs-9 {
        width: 75%;
        margin: 0 auto;
        float: none; }
    .page.page-right {
      left: 0;
      -webkit-transform: translateX(0px);
      -ms-transform: translateX(0px);
      transform: translateX(0px);
      position: static; }
      .page.page-right .panel-body {
        padding-left: 0;
        padding-right: 0; }
      .page.page-right .map.col-lg-12 {
        width: 100%; }
      .page.page-right form {
        width: 100%; }
      .page.page-right .copyright {
        display: block; }
        .page.page-right .copyright p, .page.page-right .copyright .address-detail a, .address-detail .page.page-right .copyright a {
          padding-top: 0; }
        .page.page-right .copyright .social-icons {
          padding-bottom: 4px;
          padding-top: 20px;
          width: 100%; }
      .page.page-right .col-sm-3, .page.page-right .col-xs-9 {
        width: 75%;
        margin: 0 auto;
        float: none; }

  .copyright {
    position: static; }

  .about, .contact {
    display: none; }

  .open-left .side-right,
  .open-right .side-left {
    -webkit-transform: translateX(0) scale(1);
    -ms-transform: translateX(0) scale(1);
    transform: translateX(0) scale(1); }

  .open-left .side-left,
  .open-right .side-right {
    z-index: 200;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0); }

  .open-left .side-left .main,
  .open-right .side-right .main {
    -webkit-transform: translateY(0) translateX(0) scale(1);
    -ms-transform: translateY(0) translateX(0) scale(1);
    transform: translateY(0) translateX(0) scale(1);
    opacity: 1;
    visibility: visible; }

  .open-left .page-left,
  .open-right .page-right {
    -webkit-transform: translateX(0%);
    -ms-transform: translateX(0%);
    transform: translateX(0%); }

  .reset-layout .page,
  .splitlayout.open-right .page-left,
  .splitlayout.open-left .page-right,
  .splitlayout.close-right .page-left,
  .splitlayout.close-left .page-right {
    position: static; } }
@media only screen and (max-width: 615px) {
  .detail-info h3 {
    padding-bottom: 15px; }

  .col-xs-11.pad-left-zero {
    padding-left: 15px; } }
@media only screen and (max-width: 438px) {
  .col-xs-11.pad-left-zero {
    padding-left: 0;
    width: 100%; }

  .page-right .icon {
    padding-top: 8px; } }
@media only screen and (max-width: 414px) {
  h1, h3, .side-right #countdown span,
  .side-right #countdown li.seperator {
    font-size: 35px; } }

/*# sourceMappingURL=style.css.map */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.slick-next {
  right: -0.5vw !important;
}
.slick-prev {
  left: -0.5vw !important;
}
.slick-prev::before, .slick-next::before {
  font-family: "slick";
  font-size: 2vw !important;
  line-height: 1;
  color: var(--blue) !important;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

.slick-prev, .slick-next {
  z-index: 9999 !important;
}




/*******************************************************************/
/* CUSTOM Mediaqueries */
/*****************************************************/


/*ipad and portrait*/
@media only screen and (max-width: 811px) and (orientation:portrait) {
  .principal-container-food {
    height: 58vh !important; 
    overflow-x: auto !important;
  }
  .slick-next {
    right: 1vw !important;
  }
  .slick-prev {
    left: -2vw !important;
  }
  .slick-prev::before, .slick-next::before {
    font-family: "slick";
    font-size: 5vw !important;
    line-height: 1;
    color: var(--blue) !important;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  
  }
  .list-aboutus li {
    font-size: 2vw !important;
  }
}

/*ipad and portrait*/
@media only screen and (max-width: 1081px) and (orientation:landscape){
    .side-right .supported-content {
      bottom: 30% !important;
    }
    #support .qual-desc {
      font-size: 1.5vw !important;
    }
}
/*ipad and landscape*/
@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .side-right {
        margin-top: 0;
    }
    .side-right .supported-content {
      top: 5%;
      bottom: auto;
    }
    .logo  img {
      width: 25% !important;
    }
    #typed {
      font-size: 3.5vw;
    }
    .notify-btn a {
      font-size: 2.5vw !important;
      height: 6.5vw !important;
      padding-top: 1.5vw !important;
    } 
    #support .logo-sup {
      width: 70%;
      bottom: 10%;
      left: 6%;
    }
    #support .qual-desc {
      font-size: 2.8vw !important;
    }
    .side-right .footer-authors-principal .footer-description-principal{
      font-size: 2vw;
    }
    .copyright p, .copyright i {
      font-size: 2vw;
    }
    .side-language {
        width: 60%;
    }
    .side-language .label-language {
      font-size: 1.5vw;
    }
    #select-box1 {
      font-size: 1.5vw;
      width: 10.5vw;
    }
    .call-to-action {
      height: 4vw;
      width: 4vw;
    }
    .contact {
      right: 2vw;
      top: 1.8vw;
    }
    .call-to-action i {
      font-size: 2vw;
    }
    .about {
      left: 2vw;
      top: 1.8vw;
    }

}



/*Cellpohnes*/
@media only screen and (max-width: 768px) {

      #select-box1 {
        font-size: 3vw !important;
        width: 17.5vw !important;
      }

      .slick-prev {
        left: -5vw !important;
    }
    .slick-next {
        right: 3vw !important;
    }
    .slick-prev, .slick-next {        
        height: 2vw !important;
        width: 2vw !important;
    }

    .slick-prev::before, .slick-next::before {
        font-family: "slick";
        font-size: 10vw !important;
        line-height: 1;
        color: var(--blue) !important;
        opacity: 0.75;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    #qualities .slick-slide {
      margin: 1.5vw !important;
      border-radius: 3vw !important;
    }
    .mat-form-field-appearance-fill .mat-form-field-flex {
      padding: 0 !important;
    }
    .mat-h4, .mat-subheading-1, .mat-typography h4 {
      margin: 0;
    }
    .card-header h5 {
      font-size: 4vw !important;
    }
    .valign {
      max-width: 98% !important;
      height: 100% !important;
    }
    .custom-radio {
      max-width: 32% !important;
    }
    .title-foods-section {
      font-size: 4vw !important;
     }
     .navbar.navbar-dark .navbar-toggler {
      color: #fff;
      background: #4a4a4a;
  }
   
}
